import React from 'react';
import Content from '../Content/Content';
import Container from '../Layout/SharedStyles/Container';
import SliderProducts from '../Slider/SliderProducts';
import style from './product-carousel.mod.scss';

const ProductCarousel = ({ heading, text, products }) => {
  return (
    <Container gutters={false} width="none" pt={2} pb={2}>
      <div className={style.products}>
        <Container gutters={true} width="small" pb={2}>
          <h2>{heading}</h2>
          <Content textCenter content={text} />
        </Container>
        <SliderProducts slides={products} />
      </div>
    </Container>
  );
};

export default ProductCarousel;
