import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import ModuleArea from '../components/ModularContent/ModuleArea';
import Hero from '../components/Hero/Hero';
import ProductCarousel from '../components/Product/ProductCarousel';

// import { Navigator } from '../components/LanguageHelpers/Navigator';

const HomepageTemplate = ({
  data: {
    datoCmsHomepage: {
      title,
      heroHeading,
      heroText,
      heroLink,
      heroLinkText,
      heroImage,
      heroImageMobile,
      modularContent,
      homeHeading,
      homeContent,
      products,
      metaTags
    },
  },
  pageContext,
}) => {
  return (
    <PageWrapper home pageData={pageContext} metaTags={metaTags}>
      <Hero
        heading={heroHeading}
        text={heroText}
        image={heroImage}
        mobileImage={heroImageMobile}
        link={{ to: heroLink, text: heroLinkText }}
      />
      <ProductCarousel
        heading={homeHeading}
        text={homeContent}
        products={products}
      />
      {modularContent.length > 0 && <ModuleArea blocks={modularContent} />}
    </PageWrapper>
  );
};

export default HomepageTemplate;

export const query = graphql`
  query HomepageQuery($locale: String!) {
    datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      homeHeading
      homeContent
      metaTags {
        title
        description
        image {
          url
        }
      }
      products {
        heading: title
        id: originalId
        description
        tagLine
        featureImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "472"
              fit: "fill"
              fill: "solid"
              w: "778"
              q: 60
              fillColor: "00ffffff"
              auto: "format"
            }
          )
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "800", fit: "crop", w: "1440", q: 60 }
        )
      }
      heroImageMobile: heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "800", fit: "crop", w: "400", q: 60 }
        )
      }
      heroHeading
      heroText
      heroLink {
        ... on DatoCmsOtherPage {
          id: originalId
        }
        ... on DatoCmsDealerPage {
          id: originalId
        }
        ... on DatoCmsCollection {
          id: originalId
        }
      }
      heroLinkText
      modularContent {
        ... on DatoCmsContentBlock {
          ...Content
        }
        ... on DatoCmsImageBlock {
          ...Image
        }
        ... on DatoCmsContentWithImageBlock {
          ...ContentWithImage
        }
        ... on DatoCmsSliderFullWidthBlock {
          ...SliderFullWidth
        }
        ... on DatoCmsSliderStandardWidthBlock {
          ...SliderStandardWidth
        }
        ... on DatoCmsFeatureBlock {
          ...Feature
        }
      }
    }
  }
`;
