import React from 'react';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import style from './hero.mod.scss';
import Container from '../Layout/SharedStyles/Container';
import Button from '../Button/Button';
import IconSwirlPrimary from '../Layout/Icons/IconSwirlPrimary';

const Hero = ({ heading, image, link, text, mobileImage }) => {
  const images = withArtDirection(getImage(image), [
    {
      media: '(max-width: 767px)',
      image: getImage(mobileImage),
    },
  ]);

  return (
    <div className={style.hero}>
      <div className={style.hero__image}>
        <GatsbyImage image={images} alt={image.alt || ''} />
      </div>
      <div className={style.hero__content}>
        <Container width="medium">
          <h1>{heading}</h1>
          <p>{text}</p>
          {link?.to && <Button recordId={link.to.id}>{link.text}</Button>}
        </Container>
      </div>
      <div className={style.hero__fade}>
        <IconSwirlPrimary />
      </div>
    </div>
  );
};

export default Hero;
