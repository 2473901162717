import React, { useRef, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Container from '../Layout/SharedStyles/Container';
import Button from '../Button/Button';
import style from './slider-products.mod.scss';
import './slider-products.css';

const SliderProducts = ({ slides }) => {
  const swiperRef = useRef(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const minOfSlides = 0;
  const maxOfSlides = slides.length;

  return (
    <Container width="large">
      <Swiper
        ref={swiperRef}
        spaceBetween={10}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        noSwiping={true}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={slide.id}>
            <div className={style.slider_products}>
              <GatsbyImage
                image={slide.featureImage.gatsbyImageData}
                alt={slide.featureImage.alt || ''}
              />
              <div className={style.slider_products__content}>
                <h2 className={style.slider_products__heading}>
                  {slide.heading}
                </h2>
                {slide.tagLine && (
                  <p className={style.slider_products__subHeading}>
                    {slide.tagLine}
                  </p>
                )}
                <p
                  className={style.slider_products__description}
                  dangerouslySetInnerHTML={{ __html: slide.description }}
                ></p>
                <Button recordId={slide.id}>Discover More</Button>
                <div className={style.slider_products__swiper}>
                  <span className={style.slider_products__swiper__buttons}>
                    <button
                      onClick={() => swiperRef.current.swiper.slidePrev()}
                      disabled={index === minOfSlides}
                    >
                      <ArrowBackIosNewIcon fontSize="small" />
                    </button>
                    <button
                      onClick={() => swiperRef.current.swiper.slideNext()}
                      disabled={index + 1 === maxOfSlides}
                    >
                      <ArrowForwardIosIcon fontSize="small" />
                    </button>
                  </span>
                  <span className={style.slider_products__swiper__content}>
                    <p>{index + 1}</p>
                    <p>{' / '}</p>
                    <p>{maxOfSlides}</p>
                  </span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={style.slider_products__subSwiper}>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {slides.map((slide) => (
            <SwiperSlide key={slide}>
              <div className="mySwiper__subWrapper">
                <div>
                  <GatsbyImage
                    image={slide.featureImage.gatsbyImageData}
                    alt={slide.featureImage.alt || ''}
                  />
                </div>
                <div className="mySwiper__content">
                  <label className="mySwiper__content__label">
                    {slide.heading}
                  </label>
                  <span className="mySwiper__content__icon">
                    <VisibilityIcon />
                  </span>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  );
};

export default SliderProducts;
